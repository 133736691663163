import { Link } from "gatsby"
import React, { useEffect } from "react"
import styled from "styled-components"
import Button from "../components/button"
import Layout from "../components/layout"

const StyledIndexPage = styled.div`
  position: relative;
  .index-image-container {
    position: absolute;
    right: -20%;
    transition: 1.5s ease-in-out;
    top: 40%;
    opacity: 0;
    img {
      width: 70vw;
      max-width: 1000px;
    }
    @media (max-width: 768px) {
      top: 60%;
      img {
        width: 100%;
      }
    }
  }
`

const IndexPage = () => {
  const onMount = () => {
    const image = document.querySelector(".index-image-container")
    const content = document.querySelector(".index-content")
    setTimeout(() => {
      content.style.transform = "translateY(0)"
      content.style.opacity = 1
    }, 200)
    setTimeout(() => {
      image.style.opacity = 1
      image.style.right = 0
    }, 500)
  }

  useEffect(() => {
    onMount()
  }, [])

  return (
    <Layout>
      <StyledIndexPage className="page">
        <div />
        <div className="index-content">
          <h1>
            Välkommen till
            <br />
            en värld gjord av is.
          </h1>
          <p>
            Med hjälp av fyra sylvassa medar, två kraftiga truckar och en
            formskuren bräda tillverkar vi en av Sveriges snabbaste isbrädor.
          </p>
          <a
            href="mailto:sharp4@live.se?subject=Beställning%20%E2%80%93%20Isbräda"
            className="order-button"
          >
            <Button>Beställ</Button>
          </a>
        </div>
        <div />
        <div className="index-image-container">
          <img src="/images/iceboard_go_side.png" alt="iceboard go" />
        </div>
      </StyledIndexPage>
    </Layout>
  )
}

export default IndexPage
